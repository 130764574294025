.MuiPickerStaticWrapper-content {
    background-color: black !important;
}
.MuiTypography-caption {
    color: white !important
}
.MuiCalendarPicker-root {
    width: 100% !important
}
.MuiCalendarPicker-root > div > div > button {
    color: white !important
}
.MuiPickersDay-root {
    background-color: black !important;
    color: white !important
}
.MuiPickersDay-today {
    background-color: none;
    border: 2px solid white !important;
}

.MuiPickersDay-today.Mui-selected  {
    background-color: var(--main-red) !important;

}
.MuiPickersDay-root.Mui-selected {
    background-color: var(--main-red) !important;
}
.MuiButtonBase-root.MuiPickersDay-root:hover {
    background-color: rgb(42, 42, 42) !important;
}

.MuiPickerStaticWrapper-content > div > div {
    width: 100% !important;
    max-height: 400px !important;
}

.PrivatePickersFadeTransitionGroup-root{
   overflow-y: hidden !important;
}
.PrivatePickersFadeTransitionGroup-root > div > div {
    justify-content: space-between !important;
    /* padding-bottom: 10px; */
}
.PrivatePickersFadeTransitionGroup-root > div > div .MuiTypography-root.MuiTypography-caption {
    /* width: 67px !important; */

    font-size: 16px !important;
    border-bottom: 1px solid rgb(108, 108, 108);
    margin-bottom: 5px

}
.MuiButtonBase-root.MuiPickersDay-root {
    font-size: 16px !important;
} 

.PrivatePickersSlideTransition-root > div > div {
    justify-content: space-between !important;
}

.MuiPickerStaticWrapper-root {
    overflow-x: auto !important;
}
.Mui-disabled {
    color: #616161 !important;
}