.inverted {
	background-color: var(--white) !important;
	color: var(--main-red) !important;
	border: 2px solid var(--main-red) !important;
}

.active {
	background-color: var(--main-red-active) !important;
}

.inactive {
	background-color: var(--main-black) !important;
}

.wide {
	width: 100% !important;
}
